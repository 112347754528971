import flute, { Model } from "redux-flute"
import { getCsrfHeader } from "utils/security"

const { value: csrfToken } = getCsrfHeader()

class Building extends Model {
  static schema = {
    user_id: Number,
    address1: String,
    city: String,
    county: String,
    state: String,
    zip: String,
    deposit_account_id: String,
    rent_account_id: String,
    name: String,
    unit_count: Number,
    units: Array,
    units_attributes: Array,
    managed_by: String,
    _timestamps: true,
  }

  assignAccounts = (accountType, id) => {
    const url = `${window.location.protocol}//${window.location.host}/api/v2/landlords/buildings/${id}/assign_accounts`
    const body = JSON.stringify({ [accountType]: id })
    return fetch(url, {
      method: "POST",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      credentials: "same-origin",
    })
  }

  static states = [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Puerto Rico", "PR"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"],
  ]

  static validations = {
    address1: {
      presence: [
        {
          message: "What's the street address?",
        },
      ],
      length: [
        {
          messages: {
            maximum: "The street address is too long (maximum is 50 characters).",
          },
          maximum: 50,
        },
      ],
      format: [
        {
          message: "The street address contains invalid characters.",
          with: /^[a-zA-Z0-9#()&,.'\-~_+/* ]+$/,
        },
      ],
    },
    city: {
      presence: [
        {
          message: "What city?",
        },
      ],
      format: [
        {
          message: "The street address contains invalid characters.",
          with: /^[a-zA-Z0-9#()&,.'\-~_+/* ]+$/,
        },
      ],
    },
    state: {
      presence: [
        {
          message: "What state?",
        },
      ],
      inclusion: [
        {
          message: "Please use a US state :)",
          in: [
            "Alabama",
            "AL",
            "Alaska",
            "AK",
            "Arizona",
            "AZ",
            "Arkansas",
            "AR",
            "California",
            "CA",
            "Colorado",
            "CO",
            "Connecticut",
            "CT",
            "Delaware",
            "DE",
            "District Of Columbia",
            "DC",
            "Florida",
            "FL",
            "Georgia",
            "GA",
            "Hawaii",
            "HI",
            "Idaho",
            "ID",
            "Illinois",
            "IL",
            "Indiana",
            "IN",
            "Iowa",
            "IA",
            "Kansas",
            "KS",
            "Kentucky",
            "KY",
            "Louisiana",
            "LA",
            "Maine",
            "ME",
            "Maryland",
            "MD",
            "Massachusetts",
            "MA",
            "Michigan",
            "MI",
            "Minnesota",
            "MN",
            "Mississippi",
            "MS",
            "Missouri",
            "MO",
            "Montana",
            "MT",
            "Nebraska",
            "NE",
            "Nevada",
            "NV",
            "New Hampshire",
            "NH",
            "New Jersey",
            "NJ",
            "New Mexico",
            "NM",
            "New York",
            "NY",
            "North Carolina",
            "NC",
            "North Dakota",
            "ND",
            "Ohio",
            "OH",
            "Oklahoma",
            "OK",
            "Oregon",
            "OR",
            "Pennsylvania",
            "PA",
            "Puerto Rico",
            "PR",
            "Rhode Island",
            "RI",
            "South Carolina",
            "SC",
            "South Dakota",
            "SD",
            "Tennessee",
            "TN",
            "Texas",
            "TX",
            "Utah",
            "UT",
            "Vermont",
            "VT",
            "Virginia",
            "VA",
            "Washington",
            "WA",
            "West Virginia",
            "WV",
            "Wisconsin",
            "WI",
            "Wyoming",
            "WY",
          ],
        },
      ],
    },
    zip: {
      presence: [
        {
          message: "What is the ZIP code?",
        },
      ],
      format: [
        {
          message: "Please enter a valid ZIP code :)",
          with: /^\d{5}$/,
        },
      ],
    },
    name: {
      length: [
        {
          messages: {
            maximum: "Company name is too long (maximum is 255 characters).",
          },
          maximum: 255,
        },
      ],
    },
  }
}
export default flute.model(Building)
