import Sugar from "sugar"
import { production, formatMonthDateYear } from "utils"
import { NAME_REGEX } from "utils/regex-constants"

export const composeValidators = (...validators) => (
  (value, allValues) => validators.reduce((error, validator) => error || validator(value, allValues), undefined)
)

export const required = (field) => (value) => {
  const message = field ? `${field.titlecase()} required` : "Required"
  return value ? undefined : message
}

export const requiredBoolean = (field) => (value) => {
  const message = field ? `${field.titlecase()} required` : "Required"
  return value === true || value === false ? undefined : message
}

export const isNotEmpty = (field) => (value) => {
  const message = field ? `${field.titlecase()} required` : "Required"
  return String(value).trim().length === 0 ? message : undefined
}

export const isValidDate = (value) => {
  if (!value) { return null } // let empty input handled by required
  return (Sugar.Date.isValid(Date.create(value)) ? undefined : "Please enter a valid date.")
}

export const isValidEmail = (value) => {
  // https://stackoverflow.com/questions/15017052/understanding-email-validation-using-javascript
  const re = /^\w+([\.\+-]\w+)*@\w+([\.-]\w+)*(\.\w+)+$/i
  return (re.test(String(value)) || String(value).trim().length === 0) ? undefined : "Please enter a valid email address."
}

export const isValidReferralEmail = (value) => {
  const re = /^([^@\(\)<\>\,\;\:\\\/\"\[\]\{\}\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
  return (re.test(String(value)) || String(value).trim().length === 0) ? undefined : "Please enter a valid email address."
}

export const isValidZip = (value) => {
  const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  return (re.test(String(value)) || String(value).trim().length === 0) ? undefined : "Please enter a valid zip code."
}

export const isNotMatchingEmail = (firstEmail, errorMessage) => (value) => {
  const message = errorMessage || "Email already exists in our system."
  return firstEmail.trim() !== value.trim() ? undefined : message
}

export const isDuplicateCoApplicantField = (field, applicants) => (value) => {
  const applicantData = applicants.reduce((acc, applicant) => {
    const fieldValue = applicant && applicant[field]
    if (fieldValue) { acc.push(fieldValue.toLowerCase()) }
    return acc
  }, [])
  if (applicantData.includes(value.toLowerCase())) {
    return `${field.titlecase()} is already associated with this application`
  }
}

export const isValidPassword = (value) => {
  const password = String(value)

  const length = /^.{10,20}$/
  if (!length.test(password)) {
    return "Password must be between 10 and 20 characters long."
  }

  const re = /(?=.*[a-z])(?=.*[0-9])/i
  if (!re.test(password)) {
    return "Your password must contain at least one letter and one number."
  }
}

export const isNotMatchingPassword = (password) => (value) => {
  if (!password) {
    return "Please type a password above."
  }
  if (value !== password) {
    return "Please make sure the password matches."
  }
}

export const isValidPhone = (value) => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im
  return (re.test(String(value).toLowerCase()) || String(value).trim().length === 0) ? undefined : "Please enter a valid phone number."
}

export const dateNotOlderThanMonths = (numberOfMonths, errorMessage) => (value) => {
  const message = errorMessage || "Please use more recent date."
  const today = Date.create()
  const comparisonDate = Date.create(value)
  return today.monthsSince(comparisonDate) > numberOfMonths ? message : undefined
}

export const isDateBeforeOrAfterDate = (beforeOrAfter, specifiedDate, errorMessage) => {
  const acceptableValues = ["before", "after"]
  if (!production) {
    if (!beforeOrAfter || !acceptableValues.includes(beforeOrAfter.toLowerCase())) {
      return () => "Invalid arguments in validation"
    }
  }

  return (value) => {
    const checkForBefore = beforeOrAfter.toLowerCase() === "before"
    const message = errorMessage || `Date must be ${checkForBefore ? "before" : "after"} ${formatMonthDateYear(specifiedDate) || "today"}.`
    const date = specifiedDate ? Date.create(specifiedDate) : Date.create()
    const comparisonDate = Date.create(value)
    if (checkForBefore) {
      return comparisonDate.isBefore(date) ? undefined : message
    }
    return comparisonDate.isAfter(date) ? undefined : message
  }
}

export const isNotMatchingListItems = (listItems, errorMessage) => (value) => {
  const message = errorMessage || "Please select an item from the list."
  const noMatch = !listItems.includes(value)
  return noMatch ? message : undefined
}

export const validateStringLength = (minimumLength, errorMessage) => (value) => {
  const message = errorMessage || `Must be ${minimumLength} or more characters`
  return value.length < minimumLength ? message : undefined
}

export const validateAlphaCharacters = ({ allowSpaces }) => (value) => {
  const message = allowSpaces ? "Remove any punctuation" : "Please use only letters"
  const re = allowSpaces ? /[^a-zA-Z ]/ : /[^a-zA-Z]/
  return !re.test(String(value).toLowerCase()) ? undefined : message
}

export const validateAlphaNumericSymbolCharacters = (value) => {
  const message = "Invalid character(s)"
  const re = /[^a-zA-Z0-9!@#$%^&*();:'"+=\- ]/g
  return !re.test(String(value).toLowerCase()) ? undefined : message
}

export const validateFirstAndLastName = (errorMessage) => (value) => {
  if (!value) {
    return undefined
  }

  if (!NAME_REGEX.test(value)) {
    // eslint-disable-next-line consistent-return
    return "Special characters are not accepted in the name."
  }

  const message = errorMessage || "Enter a valid first and last name"
  const arr = value.trim().split(" ") || []
  if (arr.length < 2) {
    // eslint-disable-next-line consistent-return
    return message
  }
  for (let i = 0; i < arr.length; i += 1) {
    const position = i === 0 ? "First" : "Last"
    const name = arr[i]
    if (validateStringLength(2)(name)) {
      // eslint-disable-next-line consistent-return
      return validateStringLength(2, `${position} name must be 2 or more characters`)(name)
    }
  }

  return undefined
}

export const isMatch = (comparison, errorMessage) => (value) => {
  const message = errorMessage || "Does not match records."
  return comparison.toLowerCase().trim() === value.toLowerCase().trim() ? undefined : message
}

export const isValidBirthDate = (value) => {
  const today = Date.create()
  const comparisonDate = Date.create(value)
  const oneHundredFiftyYearsAgo = new Date().rewind("150 years", true)
  let valid = true
  if (comparisonDate.isAfter(today) || comparisonDate.isBefore(oneHundredFiftyYearsAgo)) {
    valid = false
  }
  return valid ? undefined : "Please enter a valid birth date."
}

export const isEighteenYearsOld = (value) => {
  const birthDate = Date.create(value)
  const eighteenYearsAgo = Date.create().rewind("18 years")
  const isLegalAdult = birthDate.isBefore(eighteenYearsAgo)
  return isLegalAdult ? undefined : "You must be at least 18 years of age."
}

export const validateSignatureMatch = (landlordName) => (value) => {
  if (landlordName !== value) {
    return "Please make sure your signature matches the name on your account."
  }
  return null
}

export const validateMultipleEmails = (emails) => {
  // filter for disregarding unnecessary commas
  const emailArray = emails.split(",").map((email) => email.trim()).filter((el) => el)
  const hasError = emailArray.some((email) => isValidEmail(email))
  if (hasError) {
    return "Please enter a valid email address or addresses."
  }
  return null
}

export const isInvalidNumberString = (value) => {
  const numbersOnly = /^[0-9]*$/
  return !numbersOnly.test(String(value))
}

export const validateCreditCardNumber = (value) => {
  // credit cards must be numeric and can have as few as 14 digits and as many as 16 digits
  // https://stripe.com/docs/testing
  // https://developer.intuit.com/app/developer/qbpayments/docs/develop/tutorials/test-your-app#testing-credit-card-transactions
  if (isInvalidNumberString(value)) {
    return "Please enter numeric characters only."
  }
  if (value.length < 14 || value.length > 16) {
    return "Please enter a valid card number."
  }
  return null
}

export const validateCreditCardCVC = (value) => {
  // credit card cvc must be numeric and can have as few as 3 digits and as many as 4 digits
  if (isInvalidNumberString(value)) {
    return "Please enter numeric characters only."
  }
  if (value.length < 3 || value.length > 4) {
    return "Please enter a valid CVC number."
  }
  return null
}

export const validateCreditCardZip = (value) => {
  // zip code must be numeric and 5 digits long
  if (isInvalidNumberString(value)) {
    return "Please enter numeric characters only."
  }
  if (value.length !== 5) {
    return "Please enter a 5 digit zip code."
  }
  return null
}

export const cardExpired = ({ month, year }) => {
  const expiration = new Date(year, month - 1).endOfMonth()
  const today = new Date()
  return expiration.isBefore(today)
}

export const validateCreditCardExpiration = (value) => {
  // expiration must be MM/YYYY format
  const [_month, _year] = value.split("/")
  const month = Number(_month)
  let year
  if (_year) { year = Number(_year) }
  if (isInvalidNumberString(value.split("/").join(""))) {
    return "Please enter numeric characters only."
  }

  if (month < 1 || month > 12 || _month.length !== 2) {
    return "Please enter a valid month."
  }

  if (!year || _year.length !== 4) {
    return "Please enter expiration in MM/YYYY format."
  }

  if (cardExpired({ month, year })) {
    return "Card expired."
  }
  return null
}

export const isValidSSN = (value) => {
  const re = /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4,5}$/
  if (re.test(String(value).toLowerCase()) || String(value).trim().length === 0) {
    return null
  }
  return "Please enter a valid social security number."
}

export const numericality = (errorMessage) => (value) => {
  const message = errorMessage ? `${errorMessage}` : "Please enter only numbers"
  return Number.isNaN(Number(value)) ? message : null
}

export const maximumLength = (maxLength, errorMessage) => (value) => {
  if (!value) { return null }
  const message = errorMessage || `Must be ${maxLength} or less characters`
  return value.length > maxLength ? message : null
}

export const validateAdminDidNotChangeEmail = (userIsAdmin, savedEmail) => (value) => {
  if (userIsAdmin && savedEmail !== value) {
    return "Admin should not be resetting an email address."
  }
}

export const validateCharacterLength = (limit) => (value) => {
  if (!value) { return null }
  if (value.length > limit) {
    return `Please enter ${limit} characters or less.`
  }
}

export const validateUsAddress = (msg) => (value) => {
  if (!value) {
    return null
  }
  const { country, countryShort } = value
  const isShortUsAddress = countryShort && countryShort === "US"
  const isLongUsAddress = country && (country === "US" || country === "United States")
  if (isShortUsAddress || isLongUsAddress) {
    return null
  }
  return msg
}

export const validateAmountStringWithCents = (value) => {
  if (!value) {
    return null
  }
  const onlyNums = value.replace(/[^\d]/g, "")
  const amountCents = Number(onlyNums)
  if (amountCents === 0 || amountCents === 1) {
    return "Amount must be greater than $0.01"
  }
  // The largest 4 byte length integer in PostgreSQL database is 2,147,483,647
  if (amountCents > 2147483640) {
    return "Amount must be less than $21,474,836.40"
  }
  return null
}

export const isNotNegativeNumber = (fieldName) => (value) => {
  if (!value || value >= 0) {
    return null
  }
  return `${fieldName} should be greater than or equal to 0.`
}

export const isValidCurrencyWithOrWithoutCents = (value) => {
  if (!value) {
    return null
  }

  const [, cents] = value.split(".")

  if (value.includes(".") && (!cents || (cents && cents.length < 2))) {
    return "Please enter 2 digits following a period."
  }
  return null
}

export const isRequiredWithCustomMessage = (msg) => (value) => {
  if (!value) {
    return msg
  }
  return null
}

const transunionAllowedAddressCharacters = /^[a-zA-Z0-9 #&(),.'\-+~/*]+$/

export const isValidAddress = (msg) => (value) => {
  const { address1, city } = value
  const address = `${address1}, ${city}`
  if (address1.length > 50) {
    return "Street address must be 50 characters or less."
  }
  if (!value) {
    return null
  }
  return transunionAllowedAddressCharacters.test(address) ? null : msg
}

export const isValidUnitNumber = (msg) => (value) =>
  transunionAllowedAddressCharacters.test(value) ? null : msg
