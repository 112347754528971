import { gql } from "@apollo/client"
import USER_BASE_FRAGMENT from "gql/fragments/users/base"
import SUBSCRIPTION_PLAN_BASE_FRAGMENT from "gql/fragments/subscription-plans/base"
import COMPANY_BASE_FRAGMENT from "gql/fragments/company/base"
import LANDLORD_BASE_FRAGMENT from "gql/fragments/users/landlord"
import USER_TENANT_FRAGMENT from "gql/fragments/users/tenant"
import {
  USER_ANNOUNCEMENTS_FRAGMENT,
  LANDLORD_ANNOUNCEMENTS_FRAGMENT,
} from "../../../fragments/users/announcements"
import { LANDLORD_EMAILS_FRAGMENT } from "../../../fragments/users/emails"

const GET_ME_QUERY = gql`
  query getMe {
    me {
      ... on User {
        ...UserBase
        ...UserTenant
        ...UserAnnouncements
        mfa_enabled
        ghost_level
        ghost_uuid
        subscription_plan {
          ...SubscriptionPlanBase
        }
        email_verified_status
      }
      ... on Landlord {
        ...LandlordBase
        ...LandlordAnnouncements
        ...Emails
        lroc_eligible
        receives_lroc
        portfolio_size
        next_unit_price
        mfa_enabled
        ghost_level
        ghost_uuid
        subscription_plan {
          ...SubscriptionPlanBase
        }
        company {
          ...CompanyBase
        }
      }
    }
  }
  ${USER_ANNOUNCEMENTS_FRAGMENT}
  ${USER_BASE_FRAGMENT}
  ${USER_TENANT_FRAGMENT}
  ${LANDLORD_ANNOUNCEMENTS_FRAGMENT}
  ${LANDLORD_BASE_FRAGMENT}
  ${LANDLORD_EMAILS_FRAGMENT}
  ${SUBSCRIPTION_PLAN_BASE_FRAGMENT}
  ${COMPANY_BASE_FRAGMENT}
`

export default GET_ME_QUERY
