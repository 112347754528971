/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useMemo } from "react"
import { useForm, Field } from "react-final-form"
import PropTypes from "prop-types"
import { Autocomplete } from "@rent_avail/autocomplete"
import { noop, unitedStates } from "utils"
import ManualAddressInputModal from "ui_components/Input/ManualAddressInputModal"

const propTypes = {
  label: PropTypes.string,
  resource: PropTypes.objectOf(PropTypes.any),
  validate: PropTypes.arrayOf(PropTypes.func),
  formNamespace: PropTypes.string,
  onClear: PropTypes.func,
}

const defaultProps = {
  label: "Address",
  resource: null,
  validate: [noop],
  formNamespace: null,
}

const ADDRESS_FIELD_NAMES = ["address1", "city", "state", "zip", "country", "county"]

const convertNameToAbbreviation = (name) => {
  const [usState] = unitedStates.filter(([, _name]) => _name === name)
  return usState ? usState[0] : null
}

const AutocompleteAddressField = ({
  label,
  onClear,
  resource,
  validate,
  formNamespace,
  ...props
}) => {
  const form = useForm()

  const fieldNames = useMemo(
    () =>
      ADDRESS_FIELD_NAMES.reduce(
        (res, val) => ({ ...res, [val]: formNamespace ? `${formNamespace}.${val}` : val }),
        {},
      ),
    [formNamespace],
  )

  const setFormAddress = useCallback(
    ({ address1 = "", city = "", state = "", zip = "", country = "", county = "" }) => {
      form.batch(() => {
        form.change(fieldNames.address1, address1)
        form.change(fieldNames.city, city)
        form.change(fieldNames.state, state)
        form.change(fieldNames.zip, zip)
        form.change(fieldNames.country, country)
        form.change(fieldNames.county, county)
      })
    },
    [fieldNames, form],
  )

  const defaultAddressValue = useMemo(() => {
    const { address1, city, state, zip, country = "US" } = resource
    if (address1 && city && state && zip) {
      setFormAddress({ address1, city, state, zip, country })
      return `${address1}, ${city}, ${state} ${zip}. ${country}`
    }
    return null
  }, [resource, setFormAddress])

  const handleSelect = useCallback(
    ({ address_components }) => {
      if (address_components) {
        const addr = address_components.reduce(
          (result, { short_name, types: [type] }) => ({ ...result, [type]: short_name }),
          {},
        )
        const streetAddress = addr.street_number
          ? `${addr.street_number} ${addr.route}`
          : addr.route
        setFormAddress({
          address1: streetAddress,
          city:
            addr.locality ||
            addr.sublocality ||
            addr.sublocality_level_1 ||
            addr.neighborhood ||
            addr.administrative_area_level_3 ||
            addr.administrative_area_level_2,
          state: addr.administrative_area_level_1,
          zip: addr.postal_code,
          country: addr.country,
          county: addr.administrative_area_level_2,
        })
      }
    },
    [setFormAddress],
  )

  const handleManualSelection = useCallback(
    (setAutocompleteValue, [isManualOpen, setManualOpen]) => {
      const onManualFormSubmit = ({ address1, city, state, zip, country = "US" }) => {
        const abbrState = convertNameToAbbreviation(state)
        setFormAddress({ address1, city, state: abbrState, zip, country })
        const formatted_address = `${address1}, ${city}, ${abbrState}, ${zip}. US`
        setAutocompleteValue({
          formatted_address,
        })
      }
      return (
        <ManualAddressInputModal
          isOpen={isManualOpen}
          setOpen={setManualOpen}
          onFormSubmit={onManualFormSubmit}
          disableCountryField
        />
      )
    },
    [setFormAddress],
  )

  const handleClear = () => {
    setFormAddress({})
    if (onClear) {
      onClear()
    }
  }

  const validateAddress = useCallback(
    (_, values) => {
      const {
        address1,
        city,
        state,
        zip,
        country = "US",
      } = formNamespace ? values[formNamespace] : values
      const addr = address1 ? { address1, city, state, zip, country } : null
      const validations = Array.isArray(validate) ? validate : [validate]
      return validations.reduce((result, validateFunction) => {
        if (!result) {
          // eslint-disable-next-line no-param-reassign
          result = validateFunction(addr)
        }
        return result
      }, null)
    },
    [formNamespace, validate],
  )

  return (
    <Field name={fieldNames.address1} validate={validateAddress}>
      {({ input: { onBlur }, meta }) => (
        /* onBlur is passed here for FinalForm to correctly mark the fields as "touched"  */
        <Autocomplete
          defaultValue={defaultAddressValue}
          onSelect={handleSelect}
          label={label}
          onManualSelection={handleManualSelection}
          onClear={handleClear}
          onBlur={onBlur}
          error={(!meta.pristine || meta.touched) && meta.error}
          data-cy="autocomplete-field"
          autoComplete="disable-autocomplete"
          {...props}
        />
      )}
    </Field>
  )
}

AutocompleteAddressField.propTypes = propTypes
AutocompleteAddressField.defaultProps = defaultProps

export default AutocompleteAddressField
